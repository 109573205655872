.side-custom-card{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  padding: 2rem;
  padding-top: 1rem;
  max-height: 600px;
  @media all and (max-width:768px){
    padding: 1rem;
    max-height: unset;
  }
   
}

.sidbar-ul{
  list-style: none;
  transition: 0.3s;
  padding: 0;
  margin: 0;
  border-radius: 0.5rem;
  li{
    margin-bottom: 0.7rem;
    a{
      text-decoration: none;
      color: #000;
      
      font-weight: 500;
      transition: all 0.3s ease-in;
      &:hover{
        color: #ca0b2c;
        margin-left: 1.2rem;
      }
    }
    .active{
      color: #ca0b2c;
      font-weight: bold;
    }
  }
  
  


 

}