.services-banner {  
min-height: 55vh;
padding-top: 4rem;
margin-top: 2.5rem;
background-repeat: repeat;
background-size: contain;
background-position: right;
display: flex;
flex-direction: column;
justify-content: center;

h1 {
  font-size: 3rem;
  color: #fff;
 text-transform: uppercase;
}
p{
  color: #fff;
  font-size: 1.2rem;
   
}
@media all and (max-width:768px){
  background-repeat: no-repeat;
  background-size: cover;
  h1{
    font-size: 2rem;
  }
  p{
    font-size: 1rem;
  }
}
}