.contact-banner{
  margin-top: 10rem;
   
}

.contact-heading{
  font-size: 6rem;
  font-weight: 600;
  span{
    background-color: #1fd636;
    color: #fff;
    padding: 0 0.3rem;
  }

  @media all and (max-width:768px){
    font-size: 3rem;
  }
}

.contact-para{
  font-size: 1.3rem;
  margin-top: 1rem;
  font-weight: 600;
  line-height: 1.5;
a{
  color: #1fd636;
  text-decoration: none;
  margin-left: 0.5rem;
}
  @media all and (max-width:768px){
    font-size: 1.2rem;
  }
}



.benefits{
   
  padding: 3rem 0;
}

.contact-ul{
  list-style: none;
  padding: none;
  li{
    color: #7f7c7c;
    font-size: 1rem;
    .contact-icon{
      font-size: 1rem;
    }
  }
}

.contact-form{
  padding: 5rem 0;
  padding-top: 0;
  background-color: #fff;
  @media all and (max-width: 768px) {
    padding: 3rem 0;
  }
}

.form{
  background-color:#fff ;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding:2rem 1rem;
  border-radius: 0.4rem;
}

.custom-filed{
  border: 1px solid #1fd636;   
  padding: 0.7rem 1rem;
  margin-bottom: 1rem;
  outline: none;
  width: 100%;
  border-radius: 0.4rem;
}
.label{
  font-size: 1.2rem;
  color: #313231;
  font-weight: 500;
  display: block;
  margin-bottom: 0.3rem;

}
.custom-checkbox{
   padding: 1rem;
  outline: none;
  box-shadow: none;
  margin-right: 0.5rem;
  border-color: #7f7c7c !important;
  width: 16px;
  height: 16px;
}
.check-label{
  color: #313231;
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 7px;
}

.check-container{
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}
 

.submit-btn{
  background-color: rgb(31, 214, 54);
  color: #fff;
  padding: 0.7rem 1rem;
  border: none;
  border-radius: 0.4rem;
  font-size: 1.2rem;
  font-weight: 500;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover{
    background-color: #7f7c7c;
  }
}

.hide{
  display: none;
}
.contact-card{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 2rem 1rem;
  min-height: 270px;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
transition: all 0.3s ease-in-out;

&:hover{
  box-shadow: none;
}
  .contact-icon{
    background: rgb(31, 214, 54);
    background: radial-gradient(circle, rgb(31, 214, 54) 0%, rgba(31, 214, 54,0.6) 35%, rgba(0,94,184,1) 100%);
   width: 4rem;
   height: 4rem;
   border-radius: 50%;
   display: flex;
    justify-content: center;
    align-items: center;
  
    .icon{
      font-size: 1.5rem;
      color:#fff;       
    }
   
  }

  h5{
    font-weight: bold;
    margin-top: 0.7rem;
    text-transform: uppercase;
  }
  h4{
    font-weight: 600;
    color: #6f7270;
    font-size: 1rem;
  }
}

.text-none{
  text-decoration: none;
  color: unset;
}
