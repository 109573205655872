 
.why-choose {
  padding: 4rem 0;
  background-color: #ca0b2c;
  color: #fff;
  .content {
    padding-left: 2rem;
    color: #fff;
  }
  .icon {
    min-width: 150px;
  }
}

.cost {
  background-image: linear-gradient(
      to right,
      rgba(14, 105, 167, 0.95) 35%,
      rgba(14, 105, 167, 0.95)
    ),
    url("../../../asset/img/services/fire-damage.webp");
  background-size: contain;
  min-height: 30vh;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    font-size: 1.2rem;
    font-weight: 500;
  }
}


.process{
  padding: 3rem 0;
  h4{
    color: #ca0b2c;
  }
}