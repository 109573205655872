.commercial-content{
  padding: 2rem;
  @media all and (min-width:992px){
    padding: 3rem 10rem;
  }
}

#abatement-environmental-services{
  position: relative;
  .janitorial{
    background-image: url("../../../asset/img/services/mold-damage/m1.webp");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 40vh;
    min-width: 40vw;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    @media all and (max-width:992px){
      position: unset;
      min-width: none;
    }
  }
  
  
}

 

#graffity{
  position: relative;
  .janitorial{
    background-image: url("../../../asset/img/services/commercial/graffiti.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 40vh;
    object-fit: cover;
    min-width: 40vw;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    max-height: 70vh;
    @media all and (max-width:992px){
      position: unset;
      min-width: none;
    }
  }
  
}

.need{
  min-height: 60vh;
  padding-top: 4rem;
  margin-top: 2rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(90deg,rgba(000,000,000,0.9),rgba(000,000,000,0.5)), url("../../../asset/img/services/commercial/disaster.jpeg");
     
  
 
  h1{
    color: #fff;
    font-size: 2.5rem;
    font-weight: 700;
    display: block;
    align-items: center;
    @media all and (max-width:768px){
      font-size: 1.5rem;
    }
  }

  p{
    color: #fff;
    font-size: 1.5rem;
    font-weight: 500;
    align-items: center;
    @media all and (max-width:768px){
      font-size: 1rem;
    }
  }

  a{
    background-color: #ca0b2c;
    color: #fff !important;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: none;
    padding: 0.8rem;
  }

  @media all and (max-width:768px){
    background-repeat: no-repeat;
    background-size: cover;
    h1{
      font-size: 2rem;
    }
    p{
      font-size: 1rem;
    }
  }
}
.com-heading{
  font-size: 3rem;
  color: #ca0b2c;
  font-weight: bold;

  @media all and (max-width:768px){
    font-size: 2rem;
  }
}
.com-bg{
  background-color: #f7f7f7;
}

.commercial-list{
  li{
    display: flex;
    align-items: center;
    .icon{
      font-size: 2rem;
      color: #ca0b2c;
      margin-right: 1rem;
    }
  }
}