.projectImg{
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

 
.overlay{
  position: absolute;
  bottom: +10px;
  left: 0;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 50%;
  background-image: linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.8));
  padding: 2rem 1rem;
  z-index: 1;
  color: #000;
 
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
     

  h2{
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #C90B2E;
  }
}

.projectImg:hover .overlay{
  visibility: visible;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  bottom: 0;
}

.process-heading{
   font-size: 50px;
    font-weight: 700;
    margin-bottom: 1rem;
    
    span{
      font-size: 90px;
      color: #C90B2E;
    }

    @media all and (min-width: 992px) and (max-width: 1600px) {
      font-size: 40px;
      font-weight: 600;
      span{
        font-size: 50px;
      }
    }
    
}
.red{
  color: #C90B2E;
}