.mujTopNavigation {
  border-top: 4px solid #1A1A1A;
  position: fixed;
  z-index: 99999;
  background-color: #fff;
  top: 0;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  @media all and (max-width: 768px) {
    border: 0;
  }
}

.logoDiv {
  display: flex;
  align-items: center;
  .logoHeaging {
    color: #000;
    font-weight: 700;
    letter-spacing: -2px;
    margin: 0;
    transition: 0.3s;
    @media all and (max-width: 768px) {
      display: none;
    }
  }
  .logoHeagingScroll {
    font-size: 2rem !important;
  }
  .logoPara {
    color: #000;
    font-weight: 500;
    transition: 0.3s;
    @media all and (max-width: 768px) {
      display: none;
    }
  }
}
.mujLogo {
  max-width: 8rem;
  transition: 0.3s;

  @media all and (max-width: 768px) {
    max-width: 2.9rem;
  }
}
.mujLogoScrol {
  max-width: 6rem;
  transition: 0.3s;
}
.mujNumber {
  text-align: end;
  .heading {
    color: #1fd636;
    font-weight: bold;
    a {
      color: #1fd636;
    }
  }
}

.mujNav {
   
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    padding: 0rem 2rem;
    display: flex;
    justify-content: end;
    li {
      padding: 0.8rem 0.9rem;
      margin: 0 !important;
      transition: all 0.3s ease-in-out;
      white-space: nowrap;
      a {
        color: #000;
        font-size: 1.1rem;
        text-transform: capitalize;
        font-weight: 600;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        &:hover {
          color: #1fd636;
        }
      }
      .active{
        color: #1fd636;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          width: 3px;
          border-radius: 25px;
          height: 2rem;
          background-color: #1fd636;
          top: -1.8rem;
          left: 50%;
        }
      }
    }

    @media all and (min-width: 992px) and (max-width: 1400px) {
      li {
        padding: 0.8rem 0.5rem;
        a {
          font-weight: normal;
          text-transform: capitalize;
        }
      }
    }
  }
}

.mujContact {
  // background-image: linear-gradient(90deg, rgba(0,0,0,0.88),rgba(0,0,0,0.9)), url('../../asset/img/grid.webp');
  background-color: #1A1A1A;
  border-bottom: 2px solid #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .heading {
    color: #fff;
    text-align: end;
    a {
      color: #1fd636;
      text-decoration: none;
    }
  }
  @media all and (min-width: 992px) and (max-width: 1400px) {
    .heading {
      font-size: 1rem;
    }
  }
}


.handburger{
  position:absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  color: #1fd636;
  font-size: 1.8rem;
}

@keyframes animate{

  0%{
   right: -100%;
  }
  100%{
    right: 0;
  }
}
.model{
  z-index: 9999999;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.8);
  transition: all 0.3s ease-in-out;
 

  .model-inner{
    position: absolute;
    right: -100%;
    top: 0;
    height: 100%;
    width: 60%;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    padding: 1rem;
    overflow-y: scroll;
    @media all and (max-width:768px){
      width: 75%;
    }
    
  }
  .animation{
    animation: animate 0.3s ease-in-out forwards;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  }

}

.nav-logo{
  max-width: 100px;
}

.nav-cross{
  font-size: 2.5rem;

}

.list{
margin-top: 3rem;
ul{
  padding: 0;
  margin: 0;
  list-style: none;
  li{
    padding: 0.6rem 0;
    border-bottom: 1px solid #ccc;
    a{
      color: #000;
      text-decoration: none;      
      text-transform: capitalize;
      &:hover{
        color: #1fd636;
      }
    }
  }

  .inner-ul{
    padding-left: 0.5rem;
    li{
      &:last-child{
        border-bottom: 0;
      }
      &:first-child{
       border-top: 1px solid #ccc;
       margin-top: 1rem;
      }
    }
  }
}
}

.nav-contact{
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  h5{
    text-align: center;
  }
  a{
    color: #fff !important;
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    width: 100%;
    background-color: #049017;
    padding: 0.6rem 2rem;
    border-radius: 0.4rem;
    transition: all 0.3s ease-in-out;
    &:hover{
      background-color: #1fd636;
    }

 

  }
}

.dropdown {

  ul {
    display: block;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    top: calc(100% + 30px);
    margin: 0;
    min-width: 400px;
    padding: 10px;
    z-index: 99;
    opacity: 0;
     
 
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 0.5rem;
    
    li {
      min-width: 260px;    
      padding: 0.6rem 0.5rem !important;  
      .active{
        &::before{
          display: none !important;
          position: unset;
          color: #049017;
        }

      }
    }

    a {
      
      color: #049017;
      text-transform: capitalize !important;
      font-weight: normal !important;
      i {
        font-size: 0.75rem;
      }
    }

    a:hover, .active:hover, li:hover > a {
      color:#049017;
      padding-left: 1rem;
    }

  }

  &:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

}

.nav-heading{
 padding-left: 0.5rem;
 padding-bottom: 0.3rem;
 font-size: 18px;
 margin: 0.8rem 0;

 position: relative;
 &::before{
   content: '';
   position: absolute;
   width: 70%;
   height: 0.1rem;
   background-color: #1A1A1A;
   bottom: 0;
   left: 0.5rem;
 }
}

.commercial{
  font-size: 1.8rem;
  font-weight: bold;
}