.content{
  h1{
    font-size: 4rem;
    font-weight: bold;
    color: #ca0b2c;
    @media all and (max-width:768px){
      font-size: 2rem;
    }
  }
  h2{
    color: #12679F;
    font-size: 2rem;
    font-weight: 600;
  }
  h3{
    color: #12679F;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 600;
  }
  p{
    font-size: 1.2rem;
    a{
      color: #ca0b2c;
      text-decoration: none;
      font-weight: bold;
      &:hover{
        color: #12679F;
      }
    }
  }
}

.links{
  margin-top: 2rem;
  a{
    color: #fff;
    background-color: #ca0b2c;
    padding: 0.8rem 1rem;
    text-decoration: none;
 width: 300px;
    font-weight: bold;
    margin-right: 3rem;
    &:hover{
      color: #fff;
    }
  }
}