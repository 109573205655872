 


.about-content{
background-color: #F7F7F7;
padding: 3rem 0;
}

.water-content{
  padding:3rem 0;
}