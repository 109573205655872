 

.topdog{
  padding: 5rem 0;
  background-color: #fff;
  h2{  
    font-weight: 600;
    margin-bottom: 1.5rem;     
    letter-spacing: -1px; 
  }
  h4{
    color: #ca0b2c;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ca0b2c;
  }

  .testimonial{
    border: 1.5rem solid #EFF1F5;
    padding: 2rem 1.5rem;
     p{    
       text-align: center;    
     }
     .quote-left{
       position: absolute;
       top: -19px;
       left: -19px;
       z-index: 1000;
       font-size: 2.5rem;
       color: #ca0b2c;
     }
     .quote-right{
       position: absolute;
       bottom: -15px;
       right: -15px;
       font-size: 2.5rem;
       color: #ca0b2c;
       transform: rotate(180deg);
     }
   
     @media all and (max-width:768px){
       width: 95%;
     }
   }
}


.all-services{
  background-color: #EFF1F5;
}
.all-services, .remodeling{
  padding: 4rem 0;
 

  a{
    text-decoration: none;
    color: #000;
    .custom-card{
      background-color: #fff;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      transition: all 0.3s ease-in-out;
      overflow: hidden;
      min-height: 500px;
      .card-image{
        img{
          transition: all 0.3s ease-in-out;
          &:hover{
            transform: scale(1.1);
          }
        }
      }
      .card-content{
        padding: 1rem;
        background-color: #fff;
      }
    }

    &:hover{
      color: #000;
      .custom-card{
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        transform: scale(1.05);
      }
    }
  }
  
}

.serivce-page{
  letter-spacing: -1px;
  font-size: 2rem;
  text-transform: capitalize;
  color: #000 !important;
  &::before{
    top: -30px;
  }
  &::before, &::after{
    border-color: #c11a16;
  }
}

.servic-heading-4{
  text-align: center;
  color: #ca0b2c;
  margin-top: 1.4rem;
 text-transform: uppercase;
 letter-spacing: -1px;
}

.count{
  min-height: 80vh;
  padding: 4rem 0;
  color: #fff;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.88)
    ),
    url("../../../asset/img/grid.webp");
  background-size: cover;

 .h1{
    color: #fff;
    font-size: 2.5rem;
    font-weight: 700;
     letter-spacing: -1px;
     text-transform: uppercase;
     color: #c11a16;
 }
 .p{
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 2rem;
 }
 .h3{
  color: #c11a16;
 }
 .count-ul{
  color: #EFF1F5;
  margin-bottom: 2rem;
   li{
    margin-bottom: 0.4rem;
   }
 }

 p{
  a{
    color: #c11a16;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 500;
  }
 }
}

.service-page-heading{
  color: #000;
}

 
.service-links{
  display:flex;
  justify-content:center;
   
  margin-top: 4rem;
  a{
    background-color: #c11a16;
    padding: 1rem;
    color: #fff !important;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
    margin: 0 1rem;
  }

  @media all and (max-width:992px){
    flex-direction: column;
    a{
      margin-top: 1rem;
    }
  }
}

.remodeling{

}