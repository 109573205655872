

.footer{
  background-image: linear-gradient(180deg, rgba(247,247,247,0.96), rgba(247,247,247,0.9)), url("../../asset/img/usa-map.webp");
  // min-height: 30vh;
  background-position: center;
  padding: 2rem 0;
  

  
}
.phone-fixed{
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  background-color: #1fd636;
height: 45px;
width: 45px;
display: flex;
justify-content: center;
align-items: center;
  z-index: 999999;
  border-radius: 50%;
  .icon{
    color: #fff;
    font-size: 1.5rem;
  }

  @media all and (max-width:768px){
    bottom: 1.5rem;
    right: 0.7rem;
  }
}
.footer__about{
  img{
    max-width: 100px;
  }
  .footer-para{
    font-size: 0.9rem;
  }
}


.social {
  padding-bottom: 0.6rem;
  display: flex;
  align-items: top;
  .icon-container{
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: #1fd636;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.3rem; 

    .icon {  
      color: #fff;        
      font-size: 1.2rem;  
      transition: all 0.3s ease-in-out; 
   }
  }
  

  h5 {
    padding: 0 0 0 30px;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 5px;
    color: #1fd636;
  }

  p {
    padding: 0 0 0 30px;
    margin-bottom: 0;
    font-size: 0.88rem;
    color: lighten(#302e2e, 20);
    a{
      color: #302e2e !important;
      text-decoration: none;
    }    
  }  
}


.socialIcons{
  @media all and (max-width:992px){
    width: 100% !important;
  }
}

.footer-ul{
  padding: 0;
 list-style: none;
  li{
    display: inline-block;
    padding: 0.5rem 0;
    margin-right: 0.8rem;
    font-size: 0.88rem;
    color: lighten(#302e2e, 20);
    position: relative;
    &:after{
      content: "";
      position: absolute;
      top: 50%;
      right: -0.4rem;
      transform: translateY(-50%);
      height: 15px;
      width: 2px;
      background-color: lighten(#302e2e, 20);
    }
    a{
      color: #302e2e !important;
      text-decoration: none;
    }
  }
}

.social-icons{
  margin-top: 1rem;
  ul{
    padding: 0;
    list-style: none;
    display: flex;
    li{
      margin-right: 1rem;
      a{
        color: #302e2e !important;
        text-decoration: none;
        font-size: 1.3rem;
      }
    }
  }
}