.logo {
  max-width: 200px;
  margin-left: 6rem;
}
@keyframes call{
  0%{
    transform: scale(1);
  }
   
  50%{
    transform: scale(1.4);
  }
  100%{
    transform: scale(1);
  }
}
.call-container{
position: relative;
  height: 6rem;
  
  
.animation{
  border: 1px solid #fff;
  height: 4rem;
  width: 4rem;
  position: absolute;
  top: 0;
  left: 0.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: call 3s infinite ;
  margin-bottom: 1.4rem;
  .emg-call{
    background-color: #1fd636;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    
     
  }
}

@media all and (max-width:992px){
  height: 5rem;
}

}

.banner {
  min-height: 100vh;
  padding-top: 6rem;
  background-image: url("../../asset/img/grid.webp");
   

  @media all and (max-width: 768px) {
    min-height: unset;
    padding-bottom: 4rem;
    padding-top: 3.5rem;
  }
}

.fix-carousel {
  position: absolute;
  right: 0;
  clip-path: polygon(19% 0, 100% 0, 100% 100%, 0 100%, 0 18%);
  height: 80vh;
  max-width: 43%;
  display: flex;
  bottom: 0;
 
  @media all and (max-width: 992px) {
    max-width: 100%;
    position: unset;
    height: unset;
    clip-path: none;
    img {
      min-height: unset;
      width: 100%;
      object-fit: cover;
      height: 100%;
      // min-height: 50vh;
    }
  }
}

.banner-background {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.88)
    ),
    url("../../asset/img/grid.webp");
  background-size: cover;
  @media all and (min-width: 992px) {
    min-height: 95vh;
     
  }
}
.banner-right{
  
  background-image: url("../../asset/img/banner-img.webp");
  max-width: 100%;
  background-position: center;
  background-size: cover;
  min-height: 95vh;
  background-repeat: no-repeat;
  position: relative;
  
  a{
    text-decoration: none;
    color: #fff;
    &:hover{
      color: #1fd636;
    }
    .ooverlay{ 
      height: 200px;
      position: absolute;
      bottom: 0;
      right: 0;
       width: 500px;
      background-color: rgba(0, 0, 0,0.7);
      border-top-left-radius: 2rem;
      z-index: 1;
      transition: all 0.3s ease-in-out !important;
      padding: 1rem;
      @media all and (max-width:768px){
        width: 100%;
        border-radius: 0;
      }
      h1{
        font-weight: bold;
        
        font-size: 2rem;
      }
    }
  }
  
  &:hover{
    .ooverlay{
      display: block;
      opacity: 1;
    }
  }
 
  @media all and (min-width:992px){
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%);
     
  }
  @media all and (max-width:992px){
    min-height: 55vh;
  }
}

.banner-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  padding: 30px 80px 40px 80px;

  .banner-heading {
    font-size: 4rem;
    font-weight: 700;
    line-height: 70px;
    color: #FFFFFF;
    margin: 0px 0px 35px 0px;
    span {
 
      color: #1fd636;
       
    }
  }

  @media all and (max-width: 768px) {
    min-height: unset;
    padding: 1rem;
    .banner-heading {
      font-size: 2.2rem;
      line-height: 50px;
    }
  }
  @media all and (max-width:1600px){
    padding: 30px;
  }
}
.banner-fixed {
  position: absolute;
  height: calc(100% - 2rem);
  background-color: #fff;
  top: 2rem;
  left: -10em;

  .bottom {
    background-color: #444444;
    padding: 0;
    height: 100%;
    ul {
      display: block;
      padding: 0;
      margin: 0;

      li {
        a {
          padding: 1rem 0.7rem;
          position: relative;
          img {
            fill: #1fd636 !important;
          }
          span {
            position: absolute;
            left: 2.8rem;
            background-color: #444444;
            height: 100%;
            display: flex;
            align-items: center;
          }
          &:hover {
            span {
              padding: 0 0.6rem;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 1600px) {
    left: -6rem;
    top: 0.5rem;
    height: calc(100% - 1rem);
    ul {
      padding-top: 1.2rem;
    }
  }

  @media all and (max-width: 768px) {
    display: none;
  }
}
.banner-para {
  color: #fff;
  font-size: 1.1rem;
  letter-spacing: 1px;
  span {
    font-weight: 600;
    color: #1fd636;
  }
}
.z-9 {
  z-index: 9999;
}
.banner-btn {
   

  margin-top: 2rem;
  a {
    text-decoration: none;
    color: #fff;
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    padding: 0.9rem 3rem;
    font-size: 1.2rem;
    font-weight: 500;
    position: relative;
    transition: all 0.3s ease-in;
    &:hover {
      color: #1fd636;
      border: 2px solid #1fd636;
    }
    &::before {
      content: "";
      position: absolute;
      width: 1.6rem;
      height: 3px;
      background-color: #1fd636;
      right: -0.8rem;
      top: 60%;
    }
    &::after {
      content: "";
      position: absolute;
      width: 1.6rem;
      height: 3px;
      background-color: #1fd636;
      right: -0.8rem;
      top: 70%;
    }
  }

  @media all and (max-width: 768px) {
    max-width: 100%;
  }
}

.about-btn {
  a {
    color: #1fd636;
    border-color: #1fd636;

    &:hover {
      color: #fff;
      background-color: #1fd636;
    }
  }
}

.counter {
  padding: 1rem;
  background-image: url("../../asset/img/grid.webp");

  .count-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon-container{
      padding: 3rem;
      background-color: #F7F7F7;
      border-radius: 50%;
      margin-bottom: 0.5rem;
      .icon {
        display: block;
        font-size: 3rem;
        padding: 0.5rem;
  
        border-radius: 50%;
        border: 1px solid #1fd636;
        color: #1fd636;
      }
    }
   
    .number {
      font-size: 2rem;
      font-weight: 500;
    }
    h5 {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
}

.about {
  padding: 10rem 0;

  @media all and (max-width: 992px) {
    padding: 5rem 0;
  }
}

.about-header {
  position: relative;
  margin-bottom: 2rem;
  margin-left: 2rem;
  &::after {
    position: absolute;
    content: "";
    height: 110px;
    width: 100px;
    border-left: 0.8rem solid #1fd636;
    border-bottom: 0.7rem solid #1fd636;
    left: -30px;
    bottom: -1rem;
  }
  h1 {
    font-size: 3rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 2rem;
    margin-bottom: 0;
  }
  p {
    font-size: 1.1rem;
    color: #838080;
    text-transform: uppercase;
    margin-bottom: 2rem;
    margin: 0;
  }

  @media all and (max-width: 992px) {
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
    }
  }
}

.emergency {
  margin-left: 6rem;
  margin-top: 1rem;
  color: #fff !important;
  font-weight: bold;
  a {
    font-size: 1.5rem;
    color: #fff !important;
    text-decoration: none;
    font-weight: 500;
    border: 1px solid #d42026;
    padding: 0.5rem;
    transition: all 0.3s ease-in;
    background-color: #d42026;
    &:hover {
      color: #d42026 !important;
      background-color: transparent;
    }
    .phone {
      margin-right: 0.5rem;
    }
  }

  @media all and (max-width: 768px) {
    a {
      font-size: 1rem;
    }
  }
}


.bg-grad {
  background-image: linear-gradient(
    to right bottom,
    rgba(000, 00, 000, 0.8),
    rgba(000, 000, 000, 0.3)    
  );
  height :100%;

  @media all and (max-width:992px){
    background-image: unset;
  }
}
.grid {
  background-image: url("../../asset//img/grid-bg.webp");
  background-position: 0 0 !important;
  background-repeat: repeat !important;

  padding: 4rem 0;
}
.service-cont{
  padding: 4rem 0;
  // background-image:linear-gradient(90deg, rgb(1, 27, 53), rgba(1, 41, 80, 0.9)),url("../../asset/img/pattern-1.png");
  // background-size: cover;
  // background-repeat: no-repeat;
  background-color: #f7f7f7;
}

.service-heading {
  color: #1fd636;
  position: relative;
  text-transform: uppercase;
  font-size: 8rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  span{
    font-size: 5rem;
  }
  // &::before {
  //   content: "";
  //   position: absolute;
  //   width: 300px;
  //   height: 2rem;
  //   border: 0.6rem solid #1fd636;
  //   border-bottom: 0;
  //   top: -36px;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
  // &::after {
  //   content: "";
  //   position: absolute;
  //   width: 100px;
  //   height: 1rem;
  //   border-bottom: 0.3rem solid #1fd636;
  //   bottom: -10px;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
}

.hover-card {
  color: #fff;
  border: 1px solid #fff;
  padding: 1rem 1rem;
  display: flex; 
  align-items: center;  
  border-radius: 0.1rem;
  transition: all 0.3s ease-in-out;
  p {
    font-size: 0.9rem;
  }

  h4{
    margin: 0 !important;
  }
  .service-icon {
    width: 55px;    
    border: 1px solid #444444;
    padding: 0.5rem;
    margin-right: 1rem;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    border-color: #fff;
    background-color: #022241;
    object-fit: cover;
    margin-top: -0.5rem;
    .service-icon {
      border-color: #fff;
    }
  }
  min-width: 100%;
  @media all and (min-width:768px){
    min-width: 410px;
  }
 
}

.blueprint {
  padding: 4rem 0;

  h4 {
    color: #1fd636;
  }

 
}
.story {
  background-image: url("../../asset//img/story.webp");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 50vh;
  padding: 3rem 0;
}

.blue-bottom {
  padding: 3rem 0;
  min-height: 14vh;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.88)
    ),
    url("../../asset/img/grid.webp");
  background-size: cover;
}
.cert-container {
  padding: 1rem;
  display: flex;
  width: 200px;
  height: 200px;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 50%;

  img {
    max-width: 200px;
  }
}

.missionVission {
  padding-top: 5rem;

  .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    margin: 0 0 40px 0;
    background: #fff;
    box-shadow: 0 5px 26px 0 rgba(68, 88, 144, 0.14);
    transition: all 0.3s ease-in-out;
    text-align: center;
    border: 1px solid #fff;
    &:hover {
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    }
  }

  .icon {
    margin: 0 auto 1.25rem auto;
    padding-top: 17px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 72px;
    height: 72px;

    img {
      max-width: 45px;
    }
  }

  .title {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 1.13rem;
    a {
      color: #111;
    }
  }

  .description {
    font-size: 0.95rem;
    line-height: 28px;
    margin-bottom: 0;
  }

  .icon-box-pink {
    .icon {
      background: #c11a16;
      i {
        color: #ff689b;
      }
    }

    &:hover {
      border-color: #ff689b;
    }
  }

  .icon-box-cyan {
    .icon {
      background: #1fd636;
    }

    &:hover {
      border-color: #1fd636;
    }
  }
}

.cursor-pointer{
  cursor: pointer;
}
.customers {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.88),
      rgba(0, 0, 0, 0.9)
    ),
    url("../../asset/img/grid.webp");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 60vh;
  padding: 1rem;
}

.customers {
  padding-bottom: 1rem;
  color: #fff;

  .heading {
    text-align: center;
    text-transform: uppercase;
  }
  .item {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px dashed #666464;
    transition: all 0.4s ease-in-out;
    &:hover {
      border: 1px dashed #fff;
    }
    .icon {
      max-width: 70px;
      margin: 0.5rem 0;
    }
    p {
      width: 60%;
      text-align: center;
    }
  }

  @media all and (max-width: 768px) {
    .item {
      p {
        width: 100%;
      }
    }
  }
}

.masonary {
  background-color: #f7f7f7;
}

.masonry-grid {
  img {
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    &:hover {
      transform: scale(1.1);
    }
  }
}
.features {
  padding: 5rem 0;

  .heading4 {
    color: #1fd636;
  }
  .heading2 {
    font-size: 2rem;
    font-weight: 700;
  }
}

.cert-ul {
  padding: 0;
  list-style: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  li {
    display: inline-block;
    margin: 1rem;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    img {
      max-width: 140px;
    }
  }

  @media all and (max-width: 768px) {
    li {
      height: 100px;
      width: 100px;
      img {
        max-width: 80px;
      }
    }
  }
}

.cert-banner {
  margin-top: 1.6rem;
  justify-content: start;
  li {
    background-color: #fff;
    height: 90px;
    width: 174px;
    margin-left: 0;
    border-radius: 0.3rem;
    img {
      max-width: 100px;
    }
  }

  @media all and (max-width: 768px) {
    li {
      height: 80px;
      width: 140px;
      img {
        max-width: 70px;
      }
    }
  }
}
.testimonials {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.88),
      rgba(0, 0, 0, 0.9)
    ),
    url("../../asset/img/grid.webp");
  background-size: cover;
  background-repeat: no-repeat;
   

  .testimonial {
    width: 80%;
    p {
      color: #fff;
      text-align: center;
    }
    .quote-left {
      position: absolute;
      top: -40px;
      left: -30px;
      z-index: 1000;
      font-size: 3rem;
      color: #1fd636;
    }
    .quote-right {
      position: absolute;
      bottom: -30px;
      right: -30px;
      font-size: 3rem;
      color: #1fd636;
      transform: rotate(180deg);
    }

    @media all and (max-width: 768px) {
      width: 96%;
      .quote-left {
        top: -20px;
        left: -20px;
        font-size: 2rem;
      }
      .quote-right {
        bottom: -20px;
        right: -20px;
        font-size: 2rem;
      }
    }
  }
}

.card-btn {
  width: 100% !important;
  max-width: unset !important;
  a {
    padding: 0.7rem 3rem;
  }
}


.remodeling-services{
  background-image:linear-gradient(90deg, rgb(1, 27, 53), rgba(1, 41, 80, 0.9)),url("../../asset/img/pattern-1.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
   
  border-top: 1px solid #fff;
   .shape{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    opacity: 0.1;
     
  
   }
}
.cleaning-bg{
  min-height: 60vh;
  height: 100%;
  background-image: url("../../asset/img/services/restoration-lan.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}

.remodeling-bg{
  background-image: url("../../asset/img/services/remodel.jpeg");
}
.heading-heading{
  font-size: 60px;
  font-weight: 700;
  color: #fff;

  span{
    color: #1fd636;
  }

  @media all and (max-width:768px){
    font-size: 40px;
    
  }
}

.commercial 
  {
    font-size: 8rem;   
    font-weight: 700;
    span{
      font-size: 4rem;
       
    }
    @media all and (max-width:768px){
      font-size: 4rem;
      span{
        font-size: 3rem;
      }
    }
  }
  .commercial-link{
    
      text-decoration: none;
      color: #000;
      width: 100%;
      height: 100%;
      border: 2px solid #000;
      padding: 0.9rem 3rem;
      font-size: 1.2rem;
      font-weight: 500;
      position: relative;
      transition: all 0.3s ease-in;
      &:hover {
        color: #1fd636;
        border: 2px solid #1fd636;
      }
      &::before {
        content: "";
        position: absolute;
        width: 1.6rem;
        height: 3px;
        background-color: #000;
        right: -0.8rem;
        top: 60%;
      }
      &::after {
        content: "";
        position: absolute;
        width: 1.6rem;
        height: 3px;
        background-color: #000;
        right: -0.8rem;
        top: 70%;
      }
    
  
  }
 

  .main-image{
    max-width: 450px;
    border: 4px solid #fff;
    border-left-color: #1fd636;
    border-top-color: #1fd636;
    position: relative;
    @media all and (max-width:992px){     
      max-width: 100%;
      border: 0;
      }
  }

  .banner-dog-image{
    max-width: 250px;
    position: absolute;
    top:50%;
    right: -40%;     
    border: 4px solid #fff;
    border-left-color: #1fd636;
    border-top-color: #1fd636;
    p{
      font-weight: 600;
      letter-spacing: 1px;
      text-align: center;
      margin: 0;
    }


  @media all and (max-width:992px){
   position: unset; 
   display: flex;
   max-width: unset;
   width: 100%;
   border: 0;

   img{
    max-width: 60vw; 
    border-right: 4px solid #1fd636;
   }
   
   p{
    
    text-align: start;
    font-weight: 500;
    @media all and (max-width:768px){
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
    span{
      display: none;
    }
   }
    }

     

  }

  .banner-bottom{
    background-color: #fff;
    padding: 1rem;
    margin-top: 1rem;
    max-width: 60%;
    p{
      font-size: 1.1rem;
      font-weight: 700;
      text-align: center;
      margin: 0;
    }


    @media all and (max-width:992px){
      max-width: 100%;
      width: 100%;
      margin-top: 0;
      padding: 0.5rem;
      p{
        font-size: 0.8rem;
      }
    }

  }