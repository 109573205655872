@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=League+Gothic&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Titillium Web", sans-serif !important;
  box-sizing: border-box;
  overflow-x: hidden;
}

.about-para {
  font-size: 1.1rem;
  color: #1c1b1b;
  margin-bottom: 2rem;
}

.water-heading-1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #12679f;
  text-transform: uppercase;
}

.side-feature {
  padding: 1rem;
  border: 1rem solid #eaeaea;

  h6 {
    color: #12679f;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      padding-left: 0.5rem;
      padding-bottom: 0.5rem;
      font-size: 0.9rem;
      position: relative;
      font-weight: 600;
      a,
      p {
        text-decoration: none;
        color: #12679f;
        transition: all 0.3s ease-in;
        margin: 0;
        &:hover {
          color: #ca0b2c;
        }
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        width: 2px;
        height: 12px;
        background-color: #12679f;
      }
    }
  }

  @media all and (max-width: 768px) {
    padding: 0.5rem;
    border: 0.5rem solid #eaeaea;
    ul {
      li {
        font-size: 0.9rem;
        position: relative;
        font-weight: normal;
      }
    }
  }
}

.get-started {
  .left {
    background-image: linear-gradient(
        180deg,
        rgba(18, 103, 159, 0.9),
        rgba(18, 103, 159, 0.9)
      ),
      url("./asset/img/services/emergency.webp");
    min-height: 58vh;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      color: #fff;
      font-size: 2.5rem;
      font-weight: 700;
    }
    .step {
      font-size: 1.5rem;
      color: #fff;
      font-weight: 500;
    }
    .arival {
      font-size: 1.1rem;
      color: #fff;
      font-weight: 500;
      margin-bottom: 2rem;
    }
    a {
      background-color: #ca0b2c;
      color: #fff;
      text-decoration: none;
      padding: 0.7rem 2rem;
      text-align: center;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 1.1rem;
      width: fit-content;
    }

    @media all and (min-width: 992px) {
      padding: 0 30%;
    }
  }

  .right {
    color: #fff;
    background-image: linear-gradient(
        180deg,
        rgba(202, 11, 44, 0.9),
        rgba(202, 11, 44, 0.9)
      ),
      url("./asset/img/services/steps.webp");
  }
}

.white {
  color: #fff;
}

.map-image {
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}

.bottom {
  background-color: #ca0b2c;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    li {
      img {
        width: 35px;
      }
      a {
        text-decoration: none;
        color: #fff;
        font-size: 1.1rem;
        padding: 20px 25px 20px 0;
        color: #fff;
        display: flex;
        align-items: center;
        font-weight: 500;
        transition: all 0.3s;
        &:hover {
          background-color: #373536;
          padding: 20px 25px 20px 20px;
          span {
            max-width: 200px;
          }
        }
        span {
          width: auto;
          max-width: 0;
          transition: all 1s;
          white-space: nowrap;
          overflow: hidden;
          margin-left: 10px;
        }
      }
    }
  }

  @media all and (max-width: 768px) {
    ul {
      justify-content: center;

      width: 100%;
      li {
        img {
          width: 25px;
        }
        a {
          padding: 10px 10px 10px 0;
        }
      }
    }
  }

  .contact {
    color: #fff;
    background-color: #373536;
    padding: 25px;
    height: 100%;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s;
    &:hover {
      padding: 25px 35px;
    }
    @media all and (max-width: 768px) {
      padding: 10px 20px;
      display: none;
    }
  }
}
.banner-phone {
  font-size: 1.3rem;
}

.gallery-bg {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.88)
    ),
    url("../src/asset/img/grid.webp");
  background-size: cover;
  padding: 4rem 0;
}

.featured-dogs {
  padding: 5rem 0;

  @media all and (max-width: 992px) {
    padding: 2rem 0;
  }
}

.dog-card {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
  .dog-image {
    a {
      img {
        width: 100%;
        object-fit: cover;
        transition: all 0.3s ease-in-out;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
  .dog-info {
    padding: 1rem;

    ul {
      list-style: none;
      padding: 0;
      display: flex;
      margin: 0;

      li {
        background-color: #eaeaea;
        padding: 0.5rem 1rem;
        margin-right: 0.5rem;
        color: #1fd636;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    .size {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }

  .link {
    min-height: 4rem;
    position: relative;
    .link-bottom {
      position: absolute;
      bottom: 0;
      height: 3.5rem;
      width: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1rem 1rem 0 1rem;
      right: 0;
      background-color: #ca0b2c;
      .icon {
        color: #fff;
        font-size: 2rem;
      }
    }
  }
}

.load-more {
  padding: 0.8rem 0;
  font-size: 2rem;
  font-weight: bold;
  width: 100%;
  background-color: transparent;
  border: 1px solid #ca0b2c;
}

.notfound {
  padding: 5rem 0;
  background-color: #eaeaea;
  border-radius: 0.7rem;
}
.click-here{
  position: absolute;
  right: 10px;
  bottom: 10px;

}

.dog-content{
  min-height: 60vh;
  display: flex;
  align-items: center;

  h1 {
    font-size: 4rem;
    line-height: 1.4;
    font-weight: bold;
    color: #fff;
     
    span {
      font-size: 5rem;
      color: #1fd636;
    }
  }

  @media all and (max-width: 992px) {
    h1 {
      font-size: 3rem;
      font-weight: 500;
      span {
        font-size: 4rem;
      }
    }
  }

  @media all and (max-width: 768px) {
    h1 {
      font-size: 2rem;
    
      span {
        font-size: 2rem;
      }
    }
  }
}