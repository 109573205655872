.phone{
  text-decoration: none;
  color: #000;
}

.process-ul{
  list-style: none;
  padding: 0;
  margin: 0;
  li{
    margin-bottom: 1.5rem;
    b{
      font-size: 1.5rem;
      margin-right: 0.5rem;
      display: block;
    }
   
  }
}

 .choose-us{
  background-color: #F7F7F7;

 }